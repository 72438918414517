import React from "react";
import { connect } from "react-redux";
import { setFilter } from "../actions";
import Button from "@material-ui/core/Button";
import Chip from '@material-ui/core/Chip';

const Link = ({ active, children, onClick }) => {
  if (active) {
    return <Chip clickable={false} disabled onDelete={onClick} label={children}></Chip>;
  }
  return <Chip clickable={true} onClick={onClick} label={children}></Chip>;
};
const mapStateToFilterProps = (state, ownProps) => {
  return {
    active: ownProps.filter === state.visibilityFilter
  };
};
const mapDispatchToFilterProps = (dispatch, ownProps) => {
  return {
    onClick: () => dispatch(setFilter(ownProps.filter))
  };
};
export const FilterLink = connect(
  mapStateToFilterProps,
  mapDispatchToFilterProps
)(Link);
