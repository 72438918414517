import React from "react";
import { connect } from "react-redux";
import { incrementDrink, decrementDrink, resetDrinks, setFilter } from "../actions";
import { Drink } from "./Drink";
import { Footer } from "./Footer";
import { makeStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

function sortByName(a, b) {
  var nameA = a.name.toUpperCase();
  var nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export const getVisibleDrinks = (drinks, filter, charFilter) => {
  let filteredDrinks;
  drinks.sort(sortByName);
  if (charFilter !== "SHOW_ALL") {
    filteredDrinks = drinks.filter(d =>
      d.name.toLowerCase().includes(charFilter.toLowerCase())
    );
  } else {
    filteredDrinks = drinks;
  }
  switch (filter) {
    case "SHOW_ALL":
      return filteredDrinks;
    case "SHOW_ALCOHOL":
      return filteredDrinks.filter(d => d.alcoholic);
    case "SHOW_NONALCOHOL":
      return filteredDrinks.filter(d => !d.alcoholic);
    case "SHOW_NONZERO":
      return filteredDrinks.filter(d => d.counter > 0);
    default:
      return filteredDrinks;
  }
};

export const getNrDrinks = drinks => {
  let amounts = drinks.filter(d => d.counter > 0).map(d => d.counter);
  return amounts.reduce((a, b) => a + b, 0);
};

export const getTotalNrDrinks = (drinks, place) => {
  let availableDrinks = drinks.filter(d => d["price_" + place] !== "");
  return availableDrinks.length;
};

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const DrinkList = ({
  drinks,
  onDecrement,
  onIncrement,
  onResetDrinks,
  totalAmount,
  totalNrDrinks,
  place
}) => {
  const classes = useStyles();
  let d = drinks
    .filter(x => x["price_" + place] !== "")
    .map(x => (
      <Drink
        {...x}
        key={x.id}
        price={x["price_" + place]}
        onDecrement={() => onDecrement(x.id)}
        onIncrement={() => onIncrement(x.id)}
      />
    ));
  return (
    <Container>
      <Box component="span" m={1}>
       {totalNrDrinks} available, {totalAmount} selected.
      </Box>
      <Fab
        color="secondary"
        aria-label="reset"
        className={classes.fab}
        onClick={onResetDrinks}
      >
        <DeleteIcon />
      </Fab>
      <Footer />
      <List className={classes.root}>{d}</List>
    </Container>
  );
};
const mapStateToProps = state => {
  return {
    drinks: getVisibleDrinks(
      state.drinks,
      state.visibilityFilter,
      state.charFilter
    ),
    totalAmount: getNrDrinks(state.drinks),
    totalNrDrinks: getTotalNrDrinks(state.drinks, state.place),
    place: state.place
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onDecrement: e => {
      dispatch(decrementDrink(e));
      navigator.vibrate(100);
    },
    onIncrement: e => {
      dispatch(incrementDrink(e));
      navigator.vibrate(100);
    },
    onResetDrinks: e => {
      dispatch(resetDrinks());
      dispatch(setFilter("SHOW_ALL"));
    }
  };
};
export const VisibleDrinkList = connect(
  mapStateToProps,
  mapDispatchToProps
)(DrinkList);
