export const INCREMENT_DRINK = "INC_DRINK";
export const DECREMENT_DRINK = "DEC_DRINK";
export const RESET_DRINKS = "RESET_DRINKS";
export const SET_VISIBILITY_FILTER = "SET_VISIBILITY_FILTER";
export const SET_CHAR_FILTER = "SET_CHAR_FILTER";
export const SET_PLACE = "SET_PLACE";
export const SET_LOCATION = "SET_LOCATION";

export const incrementDrink = id => ({
  type: INCREMENT_DRINK,
  id: id
});

export const decrementDrink = id => ({
  type: DECREMENT_DRINK,
  id: id
});

export const resetDrinks = () => ({
  type: RESET_DRINKS
});

export const setFilter = filter => ({
  type: SET_VISIBILITY_FILTER,
  filter
});

export const setCharFilter = filter => ({
  type: SET_CHAR_FILTER,
  filter
});

export const setPlace = place => ({
  type: SET_PLACE,
  place
});

export const setLocation = location => ({
  type: SET_LOCATION,
  location
});
