import PLACES from "./places";

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function nearestPlace(latitude, longitude) {
  let distances = PLACES.map(x =>
    getDistanceFromLatLonInKm(latitude, longitude, x.lat, x.long)
  );
  let min_distance = Math.min.apply(Math, distances);
  if (min_distance > 2) {
    return { name: "nothing found" };
  }
  return PLACES[distances.indexOf(min_distance)];
}
