import React, { Component } from "react";
import PropTypes from "prop-types";
import { ReactReduxContext } from 'react-redux'
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import PLACES from "../../places";
import { PlaceList } from "../PlaceList";


export class PlacesScreen extends Component {
    static contextType = ReactReduxContext;

    constructor(props) {
        super(props);
    }

    render() {
        const { store } = this.context;
        const { onSelectPlace, history } = this.props;
        const state = store.getState();
        let location = state.location;
        return (
            <div className="placesscreen">
                <PlaceList store={store} onSelectPlace={onSelectPlace} history={history} location={location}/>
            </div>
        );
    }
}
