import React, { Component, forwardRef } from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import PLACES from "../places";
import { ReactReduxContext } from "react-redux";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import {getDistanceFromLatLonInKm} from '../utils';

const handleChange = event => {
  //   const { store } = this.context;
  console.log(event);
  console.log("sadf", event);
  //store.dispatch(setPlace(event));
};

class Place extends Component {
  // static contextType = ReactReduxContext;

  constructor(props) {
    super(props);
  }

  handleClick = (history, handler, code) => {
    handler(code);
    history.push("/drinks")
  }
  render() {
    let { name, code, lat, long, onSelectPlace, history, location } = this.props;
    let distance = 0;
    if (location) {
      distance = getDistanceFromLatLonInKm(lat, long, location[0], location[1]);
      if (isNaN(distance)) {
        distance = '';
      } else {
        distance = Math.round(distance*10)/10;
        distance += 'km';
      }
    }

    return (
      <ListItem button={true} label={name} value={code} onClick={() => this.handleClick(history, onSelectPlace, code)}>
        <ListItemText primary={name} secondary={distance}/>
      </ListItem>
    );
  }
}

export class PlaceList extends Component {
  static contextType = ReactReduxContext;

  constructor(props) {
    super(props);
    this.state = {
      place: "IN"
    };
  }

  render() {
    let { store, onSelectPlace, history, location } = this.props;


    let places = PLACES.map(x => (
      <Place {...x} key={x.code} onSelectPlace={x => onSelectPlace(x)} history={history} location={location}/>
    ));
    return <List>{places}</List>;
  }
}
