const allDrinks = 
[
  {
    "id": "1",
    "name": "Beer",
    "name_nl": "Bier",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "2",
    "name": "Coca Cola",
    "name_nl": "Coca Cola",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.3",
    "counter": 0
  },
  {
    "id": "3",
    "name": "Coca Cola Zero",
    "name_nl": "Coca Cola Zero",
    "color": "black",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.3",
    "counter": 0
  },
  {
    "id": "4",
    "name": "Coca Cola Light",
    "name_nl": "Coca Cola Light",
    "color": "gray",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.3",
    "counter": 0
  },
  {
    "id": "5",
    "name": "Fristi",
    "name_nl": "Fristi",
    "color": "pink",
    "category": "other",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.5",
    "counter": 0
  },
  {
    "id": "6",
    "name": "Fanta",
    "name_nl": "Fanta",
    "color": "orange",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.3",
    "counter": 0
  },
  {
    "id": "7",
    "name": "Lipton Ice-Tea",
    "name_nl": "Lipton Ice-Tea",
    "color": "orange",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "2.3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "8",
    "name": "Kamillethee",
    "name_nl": "Kamillethee",
    "color": "yellow",
    "category": "tea",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "0.07",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.3",
    "counter": 0
  },
  {
    "id": "9",
    "name": "Rozenbottel thee",
    "name_nl": "Rozenbottel thee",
    "color": "red",
    "category": "tea",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "0.07",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.3",
    "counter": 0
  },
  {
    "id": "10",
    "name": "Coffee",
    "name_nl": "Koffie",
    "color": "brown",
    "category": "coffee",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "0.11",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "11",
    "name": "Latte",
    "name_nl": "Latte",
    "color": "brown",
    "category": "coffee",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": true,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "12",
    "name": "Cristal",
    "name_nl": "Cristal",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "13",
    "name": "Sparkling water",
    "name_nl": "Bruiswater",
    "color": "blue",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "1.8",
    "price_ic": "0.6",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "14",
    "name": "Water",
    "name_nl": "Water",
    "color": "blue",
    "category": "other",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "1.8",
    "price_ic": "0.6",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "15",
    "name": "Chocolate",
    "name_nl": "Choco",
    "color": "brown",
    "category": "other",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": true,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "16",
    "name": "Hot chocolate",
    "name_nl": "Warme choco",
    "color": "brown",
    "category": "other",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": true,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "17",
    "name": "Sport",
    "name_nl": "Sport",
    "color": "white",
    "category": "sport",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "18",
    "name": "Sprite",
    "name_nl": "Sprite",
    "color": "white",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.3",
    "counter": 0
  },
  {
    "id": "19",
    "name": "Gini",
    "name_nl": "Gini",
    "color": "white",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "20",
    "name": "Tonic",
    "name_nl": "Tonic",
    "color": "white",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "21",
    "name": "Tonissteiner orange",
    "name_nl": "Tonissteiner orange",
    "color": "orange",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "2.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.6",
    "counter": 0
  },
  {
    "id": "22",
    "name": "Tonissteiner citron",
    "name_nl": "Tonissteiner citroen",
    "color": "yellow",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "2.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.6",
    "counter": 0
  },
  {
    "id": "23",
    "name": "Tonissteiner vruchtenkorf",
    "name_nl": "Tonissteiner vruchtenkorf",
    "color": "purple",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.7",
    "counter": 0
  },
  {
    "id": "24",
    "name": "Palm",
    "name_nl": "Palm",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "3",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.8",
    "counter": 0
  },
  {
    "id": "25",
    "name": "Duvel",
    "name_nl": "Duvel",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "3.5",
    "price_ic": "",
    "price_sporthalmaaseik": "3.8",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "26",
    "name": "Kriek",
    "name_nl": "Kriek",
    "color": "pink",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "27",
    "name": "Hoegaarden",
    "name_nl": "Hoegaarden",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "28",
    "name": "Chimay",
    "name_nl": "Chimay",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "29",
    "name": "Westmalle",
    "name_nl": "Westmalle",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "1.45",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "30",
    "name": "La Trappe Tripple",
    "name_nl": "La Trappe",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "3.7",
    "counter": 0
  },
  {
    "id": "31",
    "name": "Orval",
    "name_nl": "Orval",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "1.92",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "32",
    "name": "Rochefort",
    "name_nl": "Rochefort",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "1.65",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "33",
    "name": "Kwak",
    "name_nl": "Kwak",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "34",
    "name": "Aquarius Lemon",
    "name_nl": "Aquarius Lemon",
    "color": "yellow",
    "category": "sport",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2.5",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "35",
    "name": "Aquarius Orange",
    "name_nl": "Aquarius Orange",
    "color": "orange",
    "category": "sport",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2.5",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.8",
    "counter": 0
  },
  {
    "id": "36",
    "name": "Green Tea",
    "name_nl": "Groene thee",
    "color": "green",
    "category": "tea",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "0.09",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.3",
    "counter": 0
  },
  {
    "id": "37",
    "name": "Black Tea",
    "name_nl": "Zwarte tea",
    "color": "black",
    "category": "tea",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "0.09",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "38",
    "name": "AA",
    "name_nl": "AA",
    "color": "black",
    "category": "sport",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2.5",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "39",
    "name": "Grimbergen blond",
    "name_nl": "Grimbergen blond",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "3",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "40",
    "name": "La Chouffe",
    "name_nl": "La Chouffe",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "3.5",
    "price_ic": "",
    "price_sporthalmaaseik": "3.8",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "41",
    "name": "Karmeliet",
    "name_nl": "Karmeliet",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2.5",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "42",
    "name": "Ommegang",
    "name_nl": "Ommegang",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2.5",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "43",
    "name": "Carlsberg",
    "name_nl": "Carlsberg",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2.5",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.8",
    "counter": 0
  },
  {
    "id": "44",
    "name": "Kriek More Subite",
    "name_nl": "Kriek More Subite",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "45",
    "name": "Grimbergen donker",
    "name_nl": "Grimbergen donker",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "3",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "46",
    "name": "Leffe bruin",
    "name_nl": "Leffe bruin",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "3",
    "price_ic": "1.1",
    "price_sporthalmaaseik": "3.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "47",
    "name": "Cornet palm",
    "name_nl": "",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "3.5",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "48",
    "name": "Tonissteiner agrum",
    "name_nl": "",
    "color": "orange",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "49",
    "name": "Tonissteiner",
    "name_nl": "",
    "color": "orange",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "50",
    "name": "Tonissteiner tropical",
    "name_nl": "",
    "color": "orange",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "51",
    "name": "Schweppes tonic",
    "name_nl": "",
    "color": "orange",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "52",
    "name": "Minute Maid apple",
    "name_nl": "",
    "color": "orange",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.6",
    "counter": 0
  },
  {
    "id": "53",
    "name": "Minute Maid orange",
    "name_nl": "",
    "color": "orange",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.6",
    "counter": 0
  },
  {
    "id": "54",
    "name": "Minute Maid kers/appel",
    "name_nl": "",
    "color": "orange",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.6",
    "counter": 0
  },
  {
    "id": "55",
    "name": "Redbull",
    "name_nl": "",
    "color": "orange",
    "category": "other",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2.5",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "3.2",
    "counter": 0
  },
  {
    "id": "56",
    "name": "Porto wit",
    "name_nl": "",
    "color": "orange",
    "category": "wine",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2.5",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "57",
    "name": "Porto rood",
    "name_nl": "",
    "color": "orange",
    "category": "wine",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2.5",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "58",
    "name": "Rode wijn",
    "name_nl": "",
    "color": "orange",
    "category": "wine",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2.5",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "3.8",
    "counter": 0
  },
  {
    "id": "59",
    "name": "Witte wijn",
    "name_nl": "",
    "color": "orange",
    "category": "wine",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "2.5",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "3.8",
    "counter": 0
  },
  {
    "id": "60",
    "name": "Graanjenever",
    "name_nl": "",
    "color": "orange",
    "category": "liquor",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "",
    "price_sporthalmaaseik": "1.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "61",
    "name": "Cola redbull",
    "name_nl": "",
    "color": "orange",
    "category": "liquor",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": true,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "3",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "62",
    "name": "Bacardi",
    "name_nl": "",
    "color": "orange",
    "category": "liquor",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "3",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "63",
    "name": "Bacardi cola",
    "name_nl": "",
    "color": "orange",
    "category": "longdrink",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": true,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "3",
    "price_ic": "",
    "price_sporthalmaaseik": "5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "64",
    "name": "Martini blanco",
    "name_nl": "",
    "color": "orange",
    "category": "liquor",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "3",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "4",
    "counter": 0
  },
  {
    "id": "65",
    "name": "Whisky",
    "name_nl": "",
    "color": "orange",
    "category": "liquor",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "3.5",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "66",
    "name": "Gin tonic",
    "name_nl": "",
    "color": "orange",
    "category": "cocktail",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "3.5",
    "price_ic": "",
    "price_sporthalmaaseik": "6",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "67",
    "name": "Cava",
    "name_nl": "",
    "color": "orange",
    "category": "wine",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "3.5",
    "price_ic": "",
    "price_sporthalmaaseik": "5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "68",
    "name": "Coffee decaf",
    "name_nl": "Koffie decaf",
    "color": "brown",
    "category": "coffee",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "0.11",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "69",
    "name": "Royco",
    "name_nl": "Royco",
    "color": "brown",
    "category": "soup",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "2",
    "price_ic": "0.11",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "70",
    "name": "Espresso",
    "name_nl": "Espresso",
    "color": "brown",
    "category": "coffee",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "71",
    "name": "Koffie verkeerd",
    "name_nl": "Koffie verkeerd",
    "color": "brown",
    "category": "coffee",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "72",
    "name": "Latte macchiato",
    "name_nl": "Latte macchiato",
    "color": "brown",
    "category": "coffee",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "73",
    "name": "Cappuccino meklschuim",
    "name_nl": "Cappuccino meklschuim",
    "color": "brown",
    "category": "coffee",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "74",
    "name": "Cappuccino slagroom",
    "name_nl": "Cappuccino slagroom",
    "color": "brown",
    "category": "coffee",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "75",
    "name": "Latte speculoos",
    "name_nl": "",
    "color": "brown",
    "category": "coffee",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "76",
    "name": "Latte hazelnoot",
    "name_nl": "",
    "color": "brown",
    "category": "coffee",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "77",
    "name": "Latte karamel",
    "name_nl": "",
    "color": "brown",
    "category": "coffee",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "78",
    "name": "Pepermunt tea",
    "name_nl": "Pepermunt tea",
    "color": "black",
    "category": "tea",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.3",
    "counter": 0
  },
  {
    "id": "79",
    "name": "Bosvruchten",
    "name_nl": "Bosvruchten",
    "color": "black",
    "category": "tea",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "80",
    "name": "Cecemel",
    "name_nl": "Cecemel",
    "color": "brown",
    "category": "choco",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": true,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.3",
    "counter": 0
  },
  {
    "id": "81",
    "name": "Cecemel slagroom",
    "name_nl": "Cecemel slagroom",
    "color": "brown",
    "category": "choco",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": true,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.5",
    "counter": 0
  },
  {
    "id": "82",
    "name": "Cecemel speculoos",
    "name_nl": "Cecemel speculoos",
    "color": "brown",
    "category": "choco",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": true,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "83",
    "name": "Cecemel karamel",
    "name_nl": "Cecemel karamel",
    "color": "brown",
    "category": "choco",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": true,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "84",
    "name": "Cecemel hazelnoot",
    "name_nl": "Cecemel hazelnoot",
    "color": "brown",
    "category": "choco",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": true,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "85",
    "name": "Cecemel baileys",
    "name_nl": "Cecemel baileys",
    "color": "brown",
    "category": "choco",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": true,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "86",
    "name": "Cecemel amaretto",
    "name_nl": "Cecemel amaretto",
    "color": "brown",
    "category": "choco",
    "hot": "TRUE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": true,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "87",
    "name": "Leffe Radieuse",
    "name_nl": "Leffe Radieuse",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "88",
    "name": "Leffe Tripel",
    "name_nl": "Leffe Tripel",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "89",
    "name": "Leffe 9",
    "name_nl": "Leffe 9",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "90",
    "name": "Leffe Ruby",
    "name_nl": "Leffe Ruby",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "91",
    "name": "Julius",
    "name_nl": "Julius",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "3.9",
    "counter": 0
  },
  {
    "id": "92",
    "name": "Tripel Karmeliet",
    "name_nl": "Tripel Karmeliet",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3.8",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "93",
    "name": "Omer",
    "name_nl": "Omer",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3.8",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "3.9",
    "counter": 0
  },
  {
    "id": "94",
    "name": "Cornet",
    "name_nl": "Cornet",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3.8",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "3.9",
    "counter": 0
  },
  {
    "id": "95",
    "name": "Brugge Tripel",
    "name_nl": "Brugge Tripel",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3.8",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "3.9",
    "counter": 0
  },
  {
    "id": "96",
    "name": "Leffe Royale W.G.",
    "name_nl": "Leffe Royale W.G.",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "3.6",
    "counter": 0
  },
  {
    "id": "97",
    "name": "Chimay Blue",
    "name_nl": "Chimay Blue",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "98",
    "name": "Cubanisto",
    "name_nl": "Cubanisto",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "99",
    "name": "Corona",
    "name_nl": "Corona",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "100",
    "name": "Jupiler 0.0",
    "name_nl": "Jupiler 0.0",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.4",
    "counter": 0
  },
  {
    "id": "101",
    "name": "Hoeg. radler/rose",
    "name_nl": "Hoeg. radler/rose",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "102",
    "name": "Leffe Blond 0.0",
    "name_nl": "Leffe Blond 0.0",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "3.2",
    "counter": 0
  },
  {
    "id": "103",
    "name": "Jupiler 25cl",
    "name_nl": "Jupiler 25cl",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "TRUE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "104",
    "name": "Jupiler 33cl",
    "name_nl": "Jupiler 33cl",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "TRUE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.7",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "105",
    "name": "Jupiler 50cl",
    "name_nl": "Jupiler 50cl",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "TRUE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "106",
    "name": "Biermeter jupiler 25cl",
    "name_nl": "Biermeter jupiler 25cl",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "TRUE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "22",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "107",
    "name": "Hoegaarden",
    "name_nl": "Hoegaarden",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "TRUE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.8",
    "counter": 0
  },
  {
    "id": "108",
    "name": "Leffe Blond",
    "name_nl": "Leffe Blond",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "TRUE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "3.1",
    "counter": 0
  },
  {
    "id": "109",
    "name": "Jupiler",
    "name_nl": "Jupiler",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.1",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "110",
    "name": "Jupiler Blue",
    "name_nl": "Jupiler Blue",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "111",
    "name": "Hoegaarden Rose",
    "name_nl": "Hoegaarden Rose",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.8",
    "counter": 0
  },
  {
    "id": "112",
    "name": "Hoegaarden radler",
    "name_nl": "Hoegaarden radler",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "113",
    "name": "Kriek Belle Vle",
    "name_nl": "Kriek Belle Vle",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "114",
    "name": "Rodenbach",
    "name_nl": "Rodenbach",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "115",
    "name": "Liefmans Fruitesse",
    "name_nl": "Liefmans Fruitesse",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "116",
    "name": "Strongbow Gold AppleS",
    "name_nl": "Strongbow Gold AppleS",
    "color": "yellow",
    "category": "cider",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "117",
    "name": "Strongbow Red Berries",
    "name_nl": "Strongbow Red Berries",
    "color": "yellow",
    "category": "cider",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "118",
    "name": "Pepsi cola",
    "name_nl": "Pepsi cola",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "119",
    "name": "Pepsi max",
    "name_nl": "Pepsi max",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "120",
    "name": "Spa plat",
    "name_nl": "Spa plat",
    "color": "blue",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "121",
    "name": "Spa bruis",
    "name_nl": "Spa bruis",
    "color": "blue",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "122",
    "name": "Spa lemon-cactus",
    "name_nl": "Spa lemon-cactus",
    "color": "blue",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "123",
    "name": "Mirinda",
    "name_nl": "Mirinda",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "124",
    "name": "7 Up",
    "name_nl": "7 Up",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.2",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "125",
    "name": "Lipton Ice-Tea green",
    "name_nl": "Lipton Ice-Tea green",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "126",
    "name": "Lipton Ice-tea peach",
    "name_nl": "Lipton Ice-tea peach",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "127",
    "name": "Looza Orange",
    "name_nl": "Looza Orange",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "128",
    "name": "Looza Ace",
    "name_nl": "Looza Ace",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "129",
    "name": "Looza Appel",
    "name_nl": "Looza Appel",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "130",
    "name": "Looza Appel-Kers",
    "name_nl": "Looza Appel-Kers",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "131",
    "name": "Schweppes Agrum",
    "name_nl": "Schweppes Agrum",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "132",
    "name": "Schweppes Mojito",
    "name_nl": "Schweppes Mojito",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "133",
    "name": "Schweppes Ginger ale",
    "name_nl": "Schweppes Ginger ale",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "134",
    "name": "Oasis Tropical",
    "name_nl": "Oasis Tropical",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "135",
    "name": "Tonissteiner naranja",
    "name_nl": "Tonissteiner naranja",
    "color": "red",
    "category": "soda",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.7",
    "counter": 0
  },
  {
    "id": "136",
    "name": "AA Orange",
    "name_nl": "AA Orange",
    "color": "black",
    "category": "sport",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "137",
    "name": "AA Lemon",
    "name_nl": "AA Lemon",
    "color": "black",
    "category": "sport",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "138",
    "name": "Cecemel",
    "name_nl": "Cecemel",
    "color": "brown",
    "category": "choco",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": false,
    "caffeine": false,
    "sugar": true,
    "lactose": true,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "2.4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "2.5",
    "counter": 0
  },
  {
    "id": "139",
    "name": "Chardonnay",
    "name_nl": "Chardonnay",
    "color": "yellow",
    "category": "wine",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "140",
    "name": "Zoete witte wijn",
    "name_nl": "Zoete witte wijn",
    "color": "yellow",
    "category": "wine",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "141",
    "name": "Rose",
    "name_nl": "Rose",
    "color": "pink",
    "category": "wine",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "142",
    "name": "Merlot",
    "name_nl": "Merlot",
    "color": "red",
    "category": "wine",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "4",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "143",
    "name": "Fles Cava",
    "name_nl": "Fles Cava",
    "color": "yellow",
    "category": "wine",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "25",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "144",
    "name": "Ice Latte Vanille",
    "name_nl": "Ice Latte Vanille",
    "color": "Brown",
    "category": "coffee",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": true,
    "lactose": true,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "145",
    "name": "Ice Latte Hazelnoot",
    "name_nl": "Ice Latte Hazelnoot",
    "color": "Brown",
    "category": "coffee",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": true,
    "lactose": true,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "146",
    "name": "Ice Latte Karamel",
    "name_nl": "Ice Latte Karamel",
    "color": "Brown",
    "category": "coffee",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": false,
    "caffeine": true,
    "sugar": true,
    "lactose": true,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "147",
    "name": "Fruit jenever",
    "name_nl": "Fruit jenever",
    "color": "yellow",
    "category": "liquor",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "1.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "148",
    "name": "Limoncello",
    "name_nl": "Limoncello",
    "color": "yellow",
    "category": "liquor",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "1.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "149",
    "name": "Jagermeister",
    "name_nl": "Jagermeister",
    "color": "yellow",
    "category": "liquor",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "1.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "150",
    "name": "Sambuca",
    "name_nl": "Sambuca",
    "color": "yellow",
    "category": "liquor",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "1.5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "5.5",
    "counter": 0
  },
  {
    "id": "151",
    "name": "Amaretto",
    "name_nl": "Amaretto",
    "color": "yellow",
    "category": "liquor",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "152",
    "name": "Martini rosso",
    "name_nl": "Martini rosso",
    "color": "yellow",
    "category": "liquor",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "4",
    "counter": 0
  },
  {
    "id": "153",
    "name": "Martini Rosato",
    "name_nl": "Martini Rosato",
    "color": "yellow",
    "category": "liquor",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "154",
    "name": "Baileys",
    "name_nl": "Baileys",
    "color": "yellow",
    "category": "liquor",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "155",
    "name": "Licor 43",
    "name_nl": "Licor 43",
    "color": "yellow",
    "category": "liquor",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "3",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "156",
    "name": "Aperol Spritz",
    "name_nl": "Aperol Spritz",
    "color": "yellow",
    "category": "cocktail",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "6",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "6.5",
    "counter": 0
  },
  {
    "id": "157",
    "name": "Passoa Orange",
    "name_nl": "Passoa Orange",
    "color": "yellow",
    "category": "cocktail",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "6",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "158",
    "name": "Campari Orange",
    "name_nl": "Campari Orange",
    "color": "yellow",
    "category": "cocktail",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "6",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "159",
    "name": "Whisky cola",
    "name_nl": "Whisky cola",
    "color": "yellow",
    "category": "longdrink",
    "hot": "FALSE",
    "tap": "FALSE",
    "bottle": "FALSE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": true,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "5",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "",
    "counter": 0
  },
  {
    "id": "160",
    "name": "Leffe bruin",
    "name_nl": "Leffe bruin",
    "color": "yellow",
    "category": "beer",
    "hot": "FALSE",
    "tap": "TRUE",
    "bottle": "TRUE",
    "alcoholic": true,
    "caffeine": false,
    "sugar": false,
    "lactose": false,
    "price_sporthaldilsen": "",
    "price_ic": "",
    "price_sporthalmaaseik": "",
    "price_sporthallafelt": "",
    "price_sporthalwellen": "",
    "price_sporthalgenk": "",
    "price_sporthalbeverlo": "",
    "price_sporthallanaken": "",
    "price_wheels": "3.1",
    "counter": 0
  }
]


export default allDrinks;
