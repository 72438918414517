import React from "react";
import { connect } from "react-redux";
import { setFilter } from "../actions";
import { OrderItem } from "./OrderItem";
import { makeStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

function sortByName(a, b) {
  var nameA = a.name.toUpperCase();
  var nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export const getOrderedDrinks = (drinks) => {
  drinks.sort(sortByName);
  return drinks.filter(d => d.counter > 0);
};

export const getTotalPrice = (drinks, place) => {
  let prices = drinks
    .filter(d => d.counter > 0)
    .map(d => d.counter * Number(d["price_" + place]));
  let totalPrice = prices.reduce((a, b) => a + b, 0).toFixed(2);
  if (isNaN(totalPrice)) {
    return 0.0;
  }
  return totalPrice;
};

export const getNrDrinks = drinks => {
  let amounts = drinks.filter(d => d.counter > 0).map(d => d.counter);
  return amounts.reduce((a, b) => a + b, 0);
};

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const OrderList = ({
  drinks,
  totalPrice,
  totalAmount,
  place
}) => {
  const classes = useStyles();
  let d = drinks
    .filter(x => x["price_" + place] !== "")
    .map(x => (
      <OrderItem
        {...x}
        key={x.id}
        price={x["price_" + place]}
      />
    ));
  return (
    <Container>
      <Box component="span" m={1}>
        Selected {totalAmount} drinks totalling {totalPrice + "€"|| ""}.
      </Box>
      <List className={classes.root}>{d}</List>
    </Container>
  );
};
const mapStateToProps = state => {
  return {
    drinks: getOrderedDrinks(
      state.drinks
    ),
    totalPrice: getTotalPrice(state.drinks, state.place),
    totalAmount: getNrDrinks(state.drinks),
    place: state.place
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onShowOrder: e => {
      dispatch(setFilter("SHOW_NONZERO"));
    }
  };
};
export const VisibleOrderList = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderList);
