import React from "react";
import { FilterLink } from "./Link";
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';


export const Footer = () => {
  const [visible, setVisible] = React.useState(true);

  return (
  <div>
    <IconButton color="inherit" onClick={() => setVisible(!visible)}>
      <FilterListIcon />
    </IconButton>
    { visible ||
    <>
    <FilterLink filter="SHOW_ALL">All</FilterLink>
    <FilterLink filter="SHOW_ALCOHOL">Alcohol</FilterLink>
    <FilterLink filter="SHOW_NONALCOHOL">Non alcohol</FilterLink>
    </>
    }
  </div>
)}
;
