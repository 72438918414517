const PLACES = [
    {
      lat: 51.0236992,
      long: 5.7068167,
      code: "sporthaldilsen",
      name: "De spil"
    },
    {
      lat: 51.1013749,
      long: 5.7898875,
      code: 'sporthalmaaseik',
      name: 'Steengoed Arena'
    },
    {
      lat: 50.8292216,
      long: 5.6157401,
      code: 'sporthallafelt',
      name: 'Sporthal Kloosterhof'
    },
    {
      lat: 50.8424018,
      long: 5.3400023,
      code: 'sporthalwellen',
      name: 'Sporthal De Bloken'
    },
    {
      lat: 50.972892,
      long: 5.520525,
      code: 'sporthalgenk',
      name: 'Eurassur Hal'
    },
    {
      lat: 51.0749052,
      long: 5.249641,
      code: 'sporthalbeverlo',
      name: 'Stalvoc Volleybalclub'
    },
    {
      lat: 50.9012394,
      long: 5.6276765,
      code: 'sporthallanaken',
      name: 'Sporthal Sparrendal'
    },
    {
      lat: 50.9650241,
      long: 5.5008753,
      code: 'wheels',
      name: 'Brasserie Wheels'
    },
    {
      lat: 51.0114375,
      long: 5.7182774,
      code: "ic",
      name: "Issaris Consultancy"
    }
  ];

export default PLACES;
