import {
  RESET_DRINKS,
  INCREMENT_DRINK,
  DECREMENT_DRINK,
  SET_VISIBILITY_FILTER,
  SET_CHAR_FILTER,
  SET_LOCATION,
  SET_PLACE
} from "./actions";
import { combineReducers } from "redux";
import allDrinks from "./drinks";

const initialDrinksState = allDrinks;

const drink = (state, action) => {
  switch (action.type) {
    case RESET_DRINKS:
      return { ...state, counter: 0 };
    case INCREMENT_DRINK:
      if (state.id === action.id) {
        return { ...state, counter: state.counter + 1 };
      }
      return state;
    case DECREMENT_DRINK:
      if (state.id === action.id) {
        if (state.counter > 0) {
          return { ...state, counter: state.counter - 1 };
        }
      }
      return state;
    default:
      return state;
  }
};

export const drinks = (state = initialDrinksState, action) => {
  switch (action.type) {
    case RESET_DRINKS:
      return state.map(d => drink(d, action));
    case INCREMENT_DRINK:
      return state.map(d => drink(d, action));
    case DECREMENT_DRINK:
      return state.map(d => drink(d, action));
    default:
      return state;
  }
};

export const visibilityFilter = (state = "SHOW_ALL", action) => {
  switch (action.type) {
    case SET_VISIBILITY_FILTER:
      return action.filter;
    default:
      return state;
  }
};

export const charFilter = (state = "SHOW_ALL", action) => {
  switch (action.type) {
    case SET_CHAR_FILTER:
      return action.filter;
    default:
      return state;
  }
};

export const place = (state = "UNKNOWN", action) => {
  switch (action.type) {
    case SET_PLACE:
      return action.place;
    default:
      return state;
  }
};

export const location = (state = "UNKNOWN", action) => {
  switch (action.type) {
    case SET_LOCATION:
      return action.location;
    default:
      return state;
  }
};

const drinkApp = combineReducers({
  drinks,
  place,
  visibilityFilter,
  location,
  charFilter
});

export default drinkApp;
