import React from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import "./Drink.css";

export const Drink = ({
  onDecrement,
  onIncrement,
  name,
  color,
  counter,
  price
}) => {
  let label = name;
  if (price) {
    label += " " + price + "€";
  }
  return (
    <ListItem divider={true} alignItems="flex-start">
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: color }} />
      </ListItemAvatar>
      <ListItemText primary={label} secondary={counter}>
        {name} : {counter}
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton aria-label="Delete" onClick={onDecrement}>
          <RemoveIcon />
        </IconButton>
        <IconButton aria-label="Add" onClick={onIncrement}>
          <AddIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
Drink.propTypes = {
  onDecrement: PropTypes.func.isRequired,
  onIncrement: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  counter: PropTypes.number.isRequired
};
