import React, { Component } from "react";
import PropTypes from "prop-types";
import { ReactReduxContext } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import "./App.css";
import { setCharFilter, setPlace, setLocation } from "./actions";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { PlacesScreen } from "./components/screens/PlacesScreen";
import { DrinksScreen } from "./components/screens/DrinksScreen";
import { OrderScreen } from "./components/screens/OrderScreen";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PlaceIcon from "@material-ui/icons/Place";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from '@material-ui/core/InputBase';
import LocalBarIcon from "@material-ui/icons/LocalBar";
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import { nearestPlace } from "./utils";
import { getNrDrinks, getTotalNrDrinks } from "./components/DrinkList";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { fade } from '@material-ui/core/styles/colorManipulator';
import compose from 'recompose/compose';
const About = () => {
  return <p>
    Select a place, then choose your drinks.
    </p>
}

const styles = theme => ({
  '@global': {
    '.algolia-autocomplete': {
      '& .ds-dropdown-menu': {
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius,
        '&::before': {
          display: 'none',
        },
        '& [class^=ds-dataset-]': {
          border: 0,
          borderRadius: theme.shape.borderRadius,
          backgroundColor: theme.palette.background.paper,
        },
      },
      '& .algolia-docsearch-suggestion--category-header-lvl0': {
        color: theme.palette.text.primary,
      },
      '& .algolia-docsearch-suggestion .algolia-docsearch-suggestion--subcategory-column': {
        opacity: 1,
        padding: '5.33px 10.66px',
        textAlign: 'right',
        width: '30%',
      },
      '& .algolia-docsearch-suggestion .algolia-docsearch-suggestion--content': {
        float: 'right',
        padding: '5.33px 0 5.33px 10.66px',
        width: '70%',
      },
      '& .algolia-docsearch-suggestion--subcategory-column-text': {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightRegular,
      },
      '& .algolia-docsearch-suggestion--highlight': {
        color: theme.palette.type === 'light' ? '#174d8c' : '#acccf1',
      },
      '& .algolia-docsearch-suggestion': {
        textDecoration: 'none',
        backgroundColor: theme.palette.background.paper,
      },
      '& .algolia-docsearch-suggestion--title': theme.typography.h6,
      '& .algolia-docsearch-suggestion--text': theme.typography.body2,
      '&& .algolia-docsearch-suggestion--no-results': {
        width: '100%',
        '&::before': {
          display: 'none',
        },
      },
      '& b': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
});

class App extends Component {
  static contextType = ReactReduxContext;

  constructor(props) {
    super(props);
    this.input = React.createRef();
    if ("geolocation" in navigator) {
      console.log("geo");
      navigator.geolocation.getCurrentPosition(
        position => {
          let nearest = nearestPlace(
            position.coords.latitude,
            position.coords.longitude
          );
          this.onSetLocation([position.coords.latitude, position.coords.longitude]);
          this.onSetPlace(nearest);
        },
        x => {
          console.log("could not get location");
        }
      );
    } else {
      console.log("no geo");
    }
  }

  onSetCharFilter = e => {
    const { store } = this.context;
    store.dispatch(setCharFilter(e));
  };

  onResetCharFilter = e => {
    const { store } = this.context;
    store.dispatch(setCharFilter("SHOW_ALL"));
  };

  onSetPlace = e => {
    const { store } = this.context;
    console.log(e);
    store.dispatch(setPlace(e));
  };

  onSetLocation = e => {
    const { store } = this.context;
    store.dispatch(setLocation(e));
  };

  render() {
    const { store } = this.context;
    const { classes, width } = this.props;
    let nr_drinks_selected = 0;
    let nr_drinks_available = 1;
    let drinks = store.getState().drinks;
    if (drinks) {
      nr_drinks_selected = getNrDrinks(drinks);
      let place = store.getState().place;
      nr_drinks_available = getTotalNrDrinks(drinks, place);
      console.log("avail", nr_drinks_available);
    }

    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Toolbar>
            <Typography className={classes.title} variant="h6" noWrap>
              Waiter
          </Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputRef={this.input}
                onChange={x => this.onSetCharFilter(this.input.current.value)}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Toolbar>
          <Router>
            <div className="root">
              <Route
                path="/"
                render={({ location, history }) => (
                  <>
                    <Tabs value={location.pathname}>
                      <Tab
                        label="About"
                        value="/"
                        component={Link}
                        to="/"
                      />
                      <Tab
                        label="Places"
                        value="/places"
                        component={Link}
                        to="/places"
                      />
                      <Tab
                        label={
                          <Badge color="secondary" badgeContent={nr_drinks_available}>
                            Drinks
                        </Badge>
                        }
                        value="/drinks"
                        component={Link}
                        to="/drinks"
                      />
                      <Tab
                      label={
                        <Badge color="secondary" badgeContent={nr_drinks_selected}>
                          Order
                      </Badge>
                      }
                      value="/order"
                      component={Link}
                      to="/order"
                    />
                    </Tabs>
                    <Switch>
                      <Route path="/drinks">
                        <DrinksScreen onChange={this.onSetCharFilter} />
                      </Route>
                      <Route path="/places">
                        <PlacesScreen onSelectPlace={this.onSetPlace} history={history} />
                      </Route>
                      <Route path="/order">
                        <OrderScreen onSelectPlace={this.onSetPlace} history={history} />
                      </Route>
                      <Route path="/">
                        <About />
                      </Route>
                    </Switch>
                  </>
                )}
              />
            </div>
          </Router>{" "}
        </AppBar>
      </div>
    );
  }
}
// export default App;
// export default withStyles(styles)(App);
// export default withTheme(App);


export default compose(
  withWidth(),
  withStyles(styles),
)(App);