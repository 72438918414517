import React from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import "./Drink.css";

export const OrderItem = ({
  name,
  color,
  counter,
  price
}) => {
  let label = `${counter} ...... ${name}`;
  if (price) {
    label += " " + price + "€";
  }
  return (
    <ListItem divider={true} alignItems="center">
      <ListItemIcon>
        <Checkbox
          edge="start"
        />
      </ListItemIcon>
      <ListItemText primary={label}>
      </ListItemText>
    </ListItem>
  );
};
OrderItem.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  counter: PropTypes.number.isRequired
};
