import React, { Component } from "react";
import PropTypes from "prop-types";
import { ReactReduxContext } from 'react-redux'
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import { VisibleDrinkList } from "../DrinkList";


export class DrinksScreen extends Component {
    static contextType = ReactReduxContext;

    render() {
        const { store } = this.context;
        return (
            <div className="drinksscreen">
                <VisibleDrinkList store={store} />
            </div>
        );
    }
};
